import('happy-inputs')
    .then((happy) => (new happy.default).init())
    .then(() => import('jquery')
        .then((jQuery) => {
            window.$ = jQuery.default
            window.jQuery = window.$
        }))
    .then(() => import('./node_modules/bootstrap/dist/js/bootstrap.bundle'))
    .then(() => import('./node_modules/jquery-ui-sortable/jquery-ui.min'))
    .then(() => import('naja')
        .then((naja) => {
            window.naja = naja.default
            window.naja.initialize()
        }))
    .then(() => import('./node_modules/ublaboo-datagrid/assets/datagrid'))
    .then(() => import('nette-forms')
        .then((nette) => {
            window.Nette = nette
        }))
    .then(() => import('./node_modules/ublaboo-datagrid/assets/datagrid-instant-url-refresh'))
    .then(() => import('./node_modules/ublaboo-datagrid/assets/datagrid-spinners'))
    .then(() => import('./easymde.min')
        .then((EasyMDE) => {
            window.EasyMDE = EasyMDE.default
        }))
    .then(() => import('./easymde.init'))
